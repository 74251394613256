import React, { useEffect, useRef, useState } from 'react'
import {
  StyledCalculator,
  StyledLabel,
  StyledNumberInput,
  StyledResult,
  StyledDescription,
  StyledSliderNative,
} from './style'
import { Content } from '../../Content'
import { Heading } from '../../Heading'
import { Text } from '../../Text'
import { Wrapper } from '../../Wrapper'
import { Divider } from '../../Divider'
import { useForm } from 'react-hook-form'
import { colors } from '../../../styles/utilities/variables'

const SliderNative = ({ max, value, setValue }) => {
  const ref = useRef(null)

  // useEffect(() => {
  //   /**
  //    * Set slider value
  //    * @param {*} value
  //    */
  //   const setSliderValue = value => {
  //     const progress = (value / max) * 100
  //     ref.current.style.background = `linear-gradient(to right, ${colors.brightYellow} ${progress}%, #fff ${progress}%)`
  //   }

  //   /**
  //    * Handle slider change
  //    * @param {*} event
  //    */
  //   const handleSliderChange = event => {
  //     setSliderValue(event.target.value)
  //   }

  //   if (ref.current) {
  //     setSliderValue(value)
  //     ref.current.addEventListener('input', handleSliderChange)
  //   }
  //   return () => {
  //     if (ref.current) {
  //       ref.current.removeEventListener('input', handleSliderChange)
  //     }
  //   }
  // }, [ref])

  useEffect(() => {
    const progress = (value / max) * 100
    ref.current.style.background = `linear-gradient(to right, ${colors.brightYellow} ${progress}%, #fff ${progress}%)`
  }, [value])

  return (
    <>
      <StyledSliderNative>
        <input
          ref={ref}
          type='range'
          min='0'
          max={max}
          value={value}
          onChange={e => setValue(e.target.value)}
        />
      </StyledSliderNative>
    </>
  )
}

const roundToNearest500 = num => {
  return Math.round(num / 500) * 500
}

const CalculatorForm = ({ currency, revenues, themeColor }) => {
  const [sellers, setSellers] = useState(25)
  const [profitGoal, setProfitGoal] = useState(25000)
  const [profitFocus, setProfitFocus] = useState(false)
  const [sellersFocus, setSellersFocus] = useState(false)

  const headingColor = themeColor === 'blueTheme' ? 'pink' : 'brightYellow'
  const textColor = themeColor === 'blueTheme' ? 'white' : 'lightYellow'

  const calculateNumberOfCustomers = () => {
    const krPerSeller = profitGoal / sellers
    const averageProfit = 46
    const packetsPerSeller = krPerSeller / averageProfit
    const probableCustomers = packetsPerSeller / 4 // huh ?

    const result = Math.round(probableCustomers)

    if (result < 1) return 1
    else return result
  }

  /**
   * Handle set profit goal
   * @param {*} value
   */
  const handleSetProfitGoal = value => {
    if (value && typeof value === 'string') {
      value = value.replace('', '')
      value = value.replace('kr', '')
    }
    if (!value || value === '') {
      setProfitGoal(1)
    } else {
      setProfitGoal(parseInt(value))
    }
  }

  /**
   * Handle set sellers
   * @param {*} value
   */
  const handleSetSellers = value => {
    if (value && typeof value === 'string') {
      value = value.replace('', '')
      value = value.replace('st', '')
    }
    if (!value || value === '' || value === '0') {
      setSellers(1)
    } else {
      setSellers(parseInt(value))
    }
  }

  const formatString = (string, suffix = 'kr') => {
    return string.toLocaleString('sv-SE') + ' ' + suffix
  }

  return (
    <>
      <StyledCalculator themeColor={themeColor}>
        <Content>
          <Heading
            type='h3'
            size={'_72'}
            color={headingColor}
            lineHeight={'1.1'}
          >
            Hur mycket vill ni tjäna?
          </Heading>
          <Text color={textColor}>
            Här kan du lätt beräkna hur mycket ni behöver sälja för att nå
            målet. Det är bara att mata in siffrorna.
          </Text>
        </Content>
        <Wrapper direction='column' gap='xLarge'>
          <Wrapper direction='column' align='flex-end'>
            <Wrapper justify='space-between' align='center'>
              <StyledLabel for='profitGoal'>Försäljningsmål</StyledLabel>
              <StyledNumberInput
                type={profitFocus ? 'number' : 'text'}
                id='profitGoal'
                onFocus={() => setProfitFocus(true)}
                onBlur={() => setProfitFocus(false)}
                value={profitFocus ? profitGoal : formatString(profitGoal)}
                onChange={e => handleSetProfitGoal(e.target.value)}
              />
            </Wrapper>
            <Divider />
            <SliderNative
              min={1}
              max={60000}
              value={profitGoal}
              setValue={value => handleSetProfitGoal(roundToNearest500(value))}
            />
          </Wrapper>
          <Wrapper direction='column' align='flex-end'>
            <Wrapper justify='space-between' align='center'>
              <StyledLabel for='sellers'>Antal säljare</StyledLabel>
              <StyledNumberInput
                type={sellersFocus ? 'number' : 'text'}
                id='sellers'
                onFocus={() => setSellersFocus(true)}
                onBlur={() => setSellersFocus(false)}
                value={sellersFocus ? sellers : formatString(sellers, 'st')}
                onChange={e => handleSetSellers(e.target.value)}
              />
            </Wrapper>
            <Divider />
            <SliderNative
              min={1}
              max={60}
              value={sellers}
              setValue={value => handleSetSellers(value)}
            />
          </Wrapper>
          <Wrapper direction='column' gap='base'>
            <StyledLabel>Antal kunder att sälja till</StyledLabel>
            <StyledResult>{calculateNumberOfCustomers()} kunder</StyledResult>
            <StyledDescription>
              För att nå ert försäljningsmål behöver varje säljare i genomsnitt
              sälja till {calculateNumberOfCustomers()} kunder
            </StyledDescription>
          </Wrapper>
        </Wrapper>
        {/* <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Input
            name='sellers'
            type='number'
            defaultValue={20}
            innerRef={register({ sellersRef })}
          />
          <Form.Label for='sellers'>Antal säljare</Form.Label>
          <Wrapper align={'center'}>
            <Form.Input
              name='goal'
              type='number'
              defaultValue={16500}
              innerRef={register({ goalRef })}
            />
            <Button
              themeColor={
                themeColor === 'blueTheme' ? 'pinkTheme' : 'greenTheme'
              }
            >
              BERÄKNA
            </Button>
          </Wrapper>
          <Form.Label for='goal'>Försäljningsmål i kronor</Form.Label>
        </Form> */}
      </StyledCalculator>
      {/* <StyledCalculatorResponse>
        <StyledCalculatorResponseText themeColor={themeColor}>
          <Text color={textColor}>
            <span>{packages}</span>
          </Text>
          <Text color={textColor}>paket/person</Text>
        </StyledCalculatorResponseText>

        <Wrapper>
          <Text color={textColor}>
            För att nå ert försäljningsmål behöver varje säljare i genomsnitt
            sälja {packages} paket
          </Text>
        </Wrapper>
      </StyledCalculatorResponse> */}
    </>
  )
}

export { CalculatorForm }
