import { getString } from './lang'
import { catchError, sortByKey } from './utilities'

/**
 * Get months
 */
export const getMonthOptions = () => {
  let start = 1
  const months = [...new Array(12)].map(() => {
    return {
      name: MONTH_NAMES[start - 1],
      value: start++,
    }
  })
  return [
    {
      name: getString('FORM_MONTH_LABEL'),
      value: '',
    },
    ...months,
  ]
}

/**
 * Get month names
 */
export const MONTH_NAMES = [
  getString('FORM_JAN_LABEL'),
  getString('FORM_FEB_LABEL'),
  getString('FORM_MAR_LABEL'),
  getString('FORM_APR_LABEL'),
  getString('FORM_MAY_LABEL'),
  getString('FORM_JUN_LABEL'),
  getString('FORM_JUL_LABEL'),
  getString('FORM_AUG_LABEL'),
  getString('FORM_SEP_LABEL'),
  getString('FORM_OCT_LABEL'),
  getString('FORM_NOV_LABEL'),
  getString('FORM_DEC_LABEL'),
]

/**
 * Customer fields
 */

export const CUSTOMER_FIELDS = [
  // {
  //   name: 'name',
  //   label: 'Namn',
  //   interestFormStep: 1,
  //   validation: {
  //     required: getString('FORM_FIRST_NAME_MESSAGE'),
  //   },
  // },
  {
    name: 'firstName',
    label: getString('FORM_FIRST_NAME_LABEL'),
    interestFormStep: 1,
    validation: {
      required: getString('FORM_FIRST_NAME_MESSAGE'),
    },
  },
  {
    name: 'lastName',
    label: getString('FORM_LAST_NAME_LABEL'),
    interestFormStep: 1,
    validation: {
      required: getString('FORM_LAST_NAME_MESSAGE'),
    },
  },
  {
    name: 'street',
    label: getString('FORM_STREET_LABEL'),
    interestFormStep: 2,
    validation: {
      required: getString('FORM_STREET_MESSAGE'),
    },
  },
  {
    name: 'postalCode',
    label: getString('FORM_POSTAL_CODE_LABEL'),
    interestFormStep: 2,
    inputMode: 'numeric',
    validation: {
      required: getString('FORM_POSTAL_CODE_MESSAGE'),
      minLength: {
        value: 5,
        message: getString('FORM_POSTAL_CODE_AMOUNT_NUMBERS_MESSAGE'),
      },
      pattern: {
        value: /^[0-9]*$/i,
        message: getString('FORM_POSTAL_CODE_ONLY_NUMBERS_MESSAGE'),
      },
    },
    maxLength: 6,
  },
  {
    name: 'city',
    label: getString('FORM_CITY_LABEL'),
    interestFormStep: 2,
    validation: { required: getString('FORM_CITY_MESSAGE') },
  },
  {
    name: 'phone',
    label: getString('FORM_PHONE_LABEL'),
    type: 'tel',
    maxLength: 10,
    minLength: 10,
    interestFormStep: 1,
    // description: getString('FORM_PHONE_DESCRIPTION'),
    validation: {
      required: getString('FORM_PHONE_MESSAGE'),
      minLength: {
        value: 10,
        message: 'Vänligen fyll i ett korrekt telefonnummer.',
      },
      pattern: {
        value: /^[0-9+]*$/i,
        message: 'Telefonnumret får endast innehålla siffror',
      },
    },
  },
  {
    name: 'email',
    label: getString('FORM_EMAIL_LABEL'),
    inputMode: 'email',
    interestFormStep: 1,
    // description: getString('FORM_EMAIL_DESCRIPTION'),
    validation: {
      required: getString('FORM_EMAIL_MESSAGE'),
      validate: {
        validateEmail: value => {
          /**
           * Check for @ symbol
           */
          if (!value.includes('@')) {
            return getString('FORM_EMAIL_AT_SYMBOL_MESSAGE')
          }
          /**
           * Check for . symbol
           */
          if (!value.includes('.') && value.includes(',')) {
            return getString('FORM_EMAIL_PUNCTUATION_MESSAGE')
          }
          /**
           * Check for email format
           */
          let regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
          if (!regex.test(value)) {
            return getString('FORM_EMAIL_FORMAT_MESSAGE')
          }
        },
      },
    },
  },
]

export const BRANDS = [
  {
    value: null,
    label: 'Visitor',
    key: 'visitor',
  },
  {
    value: 'NEWBODY',
    label: 'Newbody',
    key: 'newbody',
  },
  {
    value: 'SPICY_DREAM',
    label: 'Spicy Dream',
    key: 'spicyDream',
  },
]

export const useFormOptions = (data, type = 'change') => {
  const {
    changeReasons,
    returnReasons,
    changeYears = [],
    returnYears = [],
    products = [],
    changeFromProducts = [],
    changeToProducts = [],
    shopProducts = [],
  } = data

  let categories = {}
  let filteredReturnProducts = []
  let filteredChangeFromProducts = []
  let filteredChangeToProducts = []

  const changeFromProductNumbers = changeFromProducts.map(p => p.sku)
  const changeToProductNumbers = changeToProducts.map(p => p.sku)
  const returnProductNumbers = products.map(p => p.sku)

  /**
   * Get top category
   * @param {*} shopProduct
   * @returns
   */
  const getTopCategory = shopProduct => {
    return shopProduct.categories.filter(
      c => c.parentIds.length === 3 && !['1', '2', '8', '12'].includes(c.id)
    )[0]
  }

  /**
   * Merge Product with Shop product
   * @param {*} product
   * @param {*} shopProduct
   * @returns
   */
  const mergeProduct = (product, shopProduct) => {
    return {
      ...product,
      price: shopProduct.price.value,
      currency: shopProduct.price.currency === 'SEK' ? 'kr' : '€',
      categoryId: getTopCategory(shopProduct)?.id,
      image: shopProduct.image || product.image,
      returnReasons: shopProduct.returnReasons,
      changeReasons: shopProduct.changeReasons,
    }
  }

  /**
   * Loop through Shop products
   */
  shopProducts.forEach(shopProduct => {
    const { sku } = shopProduct
    /**
     * Check if product is change or returnable...
     */
    let availableProduct = false
    if (type === 'change') {
      const changeFromIndex = changeFromProductNumbers.indexOf(sku)
      if (changeFromIndex >= 0) {
        availableProduct = true
        filteredChangeFromProducts.push(
          mergeProduct(changeFromProducts[changeFromIndex], shopProduct)
        )
      }
      const changeToIndex = changeToProductNumbers.indexOf(sku)
      if (changeToIndex >= 0) {
        availableProduct = true
        filteredChangeToProducts.push(
          mergeProduct(changeToProducts[changeToIndex], shopProduct)
        )
      }
    } else {
      const returnIndex = returnProductNumbers.indexOf(sku)
      if (returnIndex >= 0) {
        availableProduct = true
        filteredReturnProducts.push(
          mergeProduct(products[returnIndex], shopProduct)
        )
      }
    }

    /**
     * Add to category array if available
     */
    if (availableProduct) {
      // Get top category, located at 3 index
      const topCategory = getTopCategory(shopProduct)
      if (topCategory) {
        // Add category
        if (!categories[topCategory.id])
          categories[topCategory.id] = [topCategory]
      }
    }
  })

  const reasons = type === 'change' ? changeReasons : returnReasons
  const years = type === 'change' ? changeYears : returnYears

  return {
    reasons,
    categories: Object.values(categories)
      .flat()
      .map(category => {
        return {
          name: category.name,
          value: category.id,
        }
      }),
    years: [
      {
        name: getString('FORM_YEAR_LABEL'),
        value: '',
      },
      ...years.map(year => {
        return {
          name: year,
          value: year,
        }
      }),
    ],
    products: [
      ...filteredReturnProducts
        .map(product => {
          return {
            ...product,
            name: product.name,
            value: product.sku,
            query: `${product.name} ${product.sku}`,
            image: product.image,
          }
        })
        .sort(sortByKey('name')),
    ],
    changeFromProducts: [
      ...filteredChangeFromProducts
        .map(product => {
          return {
            ...product,
            name: product.name,
            value: product.sku,
            query: `${product.name} ${product.sku}`,
            image: product.image,
          }
        })
        .sort(sortByKey('name')),
    ],
    changeToProducts: [
      ...filteredChangeToProducts
        .map(product => {
          return {
            ...product,
            name: product.name,
            value: product.sku,
            query: `${product.name} ${product.sku}`,
            image: product.image,
          }
        })
        .sort(sortByKey('name')),
    ],
  }
}

export const REQUIRED_TEXT = getString('FORM_REQUIRED_TEXT')

/**
 * Base 64 encode file
 * This function has resize in it
 * @param {*} file
 */
export const toBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    const mimeType = file.type
    reader.onload = () => {
      let image = new Image()
      image.onload = () => {
        // Resize the image
        let canvas = document.createElement('canvas'),
          max_size = 500,
          width = image.width,
          height = image.height

        if (width > height) {
          if (width > max_size) {
            height *= max_size / width
            width = max_size
          }
        } else {
          if (height > max_size) {
            width *= max_size / height
            height = max_size
          }
        }
        canvas.width = width
        canvas.height = height
        canvas.getContext('2d').drawImage(image, 0, 0, width, height)
        const dataUrl = canvas.toDataURL('image/jpeg') // Create JPEG for size
        resolve(dataUrl)
      }
      image.onerror = error => reject(error)
      image.src = reader.result
    }
    reader.readAsDataURL(file)
    reader.onerror = error => reject(error)
  })

/**
 * Base64 fallback
 * @param {*} file
 */
const toBase64Fallback = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })

/**
 * Parse Form item
 * @param {*} item
 */
const parseFormItem = async item => {
  return new Promise(async (resolve, reject) => {
    const image1 = item.image1[0]
    const image2 = item.image2[0]
    let image1Type = 'image/jpeg'
    let image2Type = 'image/jpeg'

    /**
     * Try resize image and base64 encode
     */
    let image1Data = await toBase64(image1).catch(error =>
      catchError(error, {
        image1Name: image1.name,
        image1Type: image1.type,
      })
    )
    let image2Data = await toBase64(image2).catch(error =>
      catchError(error, {
        image2Name: image2.name,
        image2Type: image2.type,
      })
    )

    /**
     * If error, base64 encode original
     */
    if (!image1Data) {
      image1Data = await toBase64Fallback(image1).catch(error =>
        catchError(error)
      )
      image1Type = image1.type
    }
    if (!image2Data) {
      image2Data = await toBase64Fallback(image2).catch(error =>
        catchError(error)
      )
      image2Type = image2.type
    }

    /**
     * Still error...?
     * Generate a placeholder image
     */
    const placeholder =
      'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVQYV2NgYAAAAAMAAWgmWQ0AAAAASUVORK5CYII='
    if (!image1Data) {
      image1Data = placeholder
      image2Type = 'image/png'
    }
    if (!image2Data) {
      image2Data = placeholder
      image2Type = 'image/png'
    }

    const newItem = {
      ...item,
      image1: {
        name: image1.name,
        data: image1Data,
        mimeType: image1Type,
      },
      image2: {
        name: image2.name,
        data: image2Data,
        mimeType: image2Type,
      },
    }
    resolve(newItem)
  })
}

/**
 * Parse form data
 * @param {*} data
 */
export const parseFormData = async data => {
  return new Promise(async (resolve, reject) => {
    let newItems = []
    await asyncForEach(data.items, async item => {
      const newItem = await parseFormItem(item)
      newItems.push(newItem)
    })
    resolve({
      ...data,
      items: newItems,
    })
  })
}

/**
 * Async foreach
 * @param {*} array
 * @param {*} callback
 */
const asyncForEach = async (array, callback) => {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array)
  }
}

/**
 * Loop through object of errors passed back by Gravity Forms
 * Set errors to the corrosponding input
 */
export const handleGravityFormsValidationErrors = (data, setError) => {
  Object.keys(data).forEach(function(key) {
    const id = key.replace('.', '_')
    const fieldId = `input_${id}`
    setError(fieldId, {
      type: 'gf_validation',
      message: data[key],
    })
  })
}
